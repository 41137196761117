@import "../helpers/variables.scss";

.assessment-container {
    display: flex;
    gap: 2rem;
    flex-direction: column;
    margin: 3rem 0;
    min-width: 600px;

    & svg{
        cursor: pointer;
    }

    & .assessment-question__content-slider__desktop .slider{
        flex: 1 1;
    }

    & .assessment-question__content-text textarea{
        text-align: center;
    }
}

.tile {
    max-width: 75%;
    min-width: 75%;
    margin: auto;

    &__inner {
        padding: 1rem;
        background-color: $s-grey5;
        border-radius: $default-radius;
        display: flex;
        align-items: center;

        &-drag{
            width: 5%;
        }

        &-content{
            width: 100%;
        }
    }

    &__header {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: .5rem;
    }

    &__avatar {
        margin-right: .5rem;
    }

    &__name {

        &-text {
            font-size: $body-regular;
            line-height: 1.1875;
            font-weight: 700;
        }
    }

    &__date {
        position: absolute;
        top: 0;
        right: 0;

        &-text {
            color: $s-grey3;
            font-size: $caption-m;
            line-height: 1rem;
        }
    }

    &__role {
        margin-left: auto;
        padding-left: 1rem;
        font-size: $caption-b;
        line-height: 1.1667;
        font-weight: 700;
    }

    &__body {
        margin-top: .5rem;

        &-header{
            margin-bottom: 10px;
            display: flex;
            align-items: center;
        }

        &-action{
            display: flex;
            gap: 0.5rem;
            margin: 0 5px;
        }
    }
}

.assessment-question {
    margin-bottom: 1rem;

    &__content {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &-text {
            width: 100%;

            &>div {
                background-color: transparent;
            }
        }

        &-slider {
            margin-top: 1rem;

            &__desktop {
                display: flex;
                flex-direction: row;
                gap: 2rem;
                align-items: center;

                & .label {
                    flex: 1;
                    text-align: center;
                }

                & .slider {
                    flex: 4;
                }
            }
        }
    }
}