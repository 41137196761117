@import './base/base';
@import './layout/layout';
@import './module/module';
@import './theme/theme';

h3 {
  margin: 20px;
}

button {
  margin-top: 10px;
  margin-left: 10px;
}

.pagination {
  margin-left: 10px;
}

h3 button {
  margin-top: -5px;
}

h1 {
  margin: 20px;
}

.header-img-icon img {
  border-radius: 15px;
}

.img-list {
  width: 30px;
  border-radius: 15px;
}

.gap-1 {
  gap: 1rem;
}

.gap-4 {
  gap: 1.5rem;
}

.form {
  &__container {
    display: flex;
    gap: 1rem;
    flex-direction: row;
    flex-wrap: wrap;

    &>div {
      width: 49%;
    }

    @media screen and (max-width: 870px) {
      &>div {
        width: 100%;
      }
    }
  }
}