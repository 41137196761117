// Colors
$p-blue1: #2C629B;
$p-blue2: #618CB9;
$p-blue3: #8db1d7;
$p-blue4: #ACBBCB;
$p-blue5: #DEEEFF;

$s-grey1: #000000;
$s-grey2: #353535;
$s-grey3: #8A8A8A;
$s-grey4: #D9D9D9;
$s-grey5: #F5F5F5;

$ui-red: #FF5555;


// Typography
$heading-lg: 3rem;
$heading-md: 1.75rem;
$heading-sm: 1.25rem;
$heading-xl: 2.5rem;

$body-regular: 1rem;
$body-bold: 1rem;
$body-medium: 1.0625rem;

$caption-m: .8125rem;
$caption-b: .75rem;


// radius
$default-radius: 8px;
$small-radius: 4px;
$large-radius: 12px;
$btn-radius: $default-radius;
$input-radius: $default-radius;
$badge-radius: $default-radius;
$emoji-dropdown-radius: $large-radius;
$tile-radius: $default-radius;