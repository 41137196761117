@keyframes pulse {
  0% {
    opacity: 1;
    width: 7px;
    height: 7px;
    left: 0;
    top: 0;
  }
  95% {
    opacity: 0.1;
    left: -10.5px;
    top: -10.5px;
    width: 28px;
    height: 28px;
  }
  100% {
    opacity: 0;
    width: 7px;
    height: 7px;
    left: 0;
    top: 0;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

header nav a {
  color: #18214d !important;
}
header nav a:hover {
  color: #007bff;
}
header .navbar-brand {
  padding: 0;
}
header .navbar-brand img {
  height: 45px;
}

.navbar-nav{
  display: flex;
  gap: 2rem;
  padding: 0 2rem;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}
header nav .nav-item.active a {
  color: #007bff;
}
header .nav-link.btn {
  padding: 5px 0;
}
.circle-pulse {
  background: #0089ff;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  position: absolute;
  right: 12px;
  top: 8px;
}

.circle-pulse:after {
  background-color: #0089ff;
  content: '';
  display: table;
  border-radius: 50%;
  position: absolute;
  animation-name: pulse;
  animation-duration: 0.9s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}
.header-custom-icon .nav-link {
  line-height: 0;
  margin-top: 6px;
}
.header-custom-icon button {
  padding: 0 15px;
  margin-top: 5px;
}
.header-img-icon button {
  padding: 0 0 0 15px;
}
.header-custom-icon button:after,
.header-img-icon button:after {
  display: none;
}

.header-custom-icon button:focus,
.header-img-icon button:focus {
  box-shadow: none;
}
.dropdown-item {
  color: #18214d;
  font-size: 14px;
}
.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item:active {
  background-color: #f8f9fa;
  color: #16181b;
}
.header-img-icon .nav-link.dropdown-toggle {
  padding-right: 0;
}
.header-custom-icon a:after,
.header-img-icon a:after {
  display: none;
}
.header-custom-icon .dropdown-menu,
.header-img-icon .dropdown-menu {
  padding: 0;
  margin-top: 8px;
}
.dropdown-menu {
  max-width: 280px;
  position: absolute;
  right: 0;
  left: auto;
  border-radius: 5px;
  margin-top: 12px !important;
  border: 1px solid #f2f4f9;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 14px -6px;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: slideIn;
}
.dropdown-menu::before {
  content: '';
  width: 13px;
  height: 13px;
  background: #fff;
  position: absolute;
  top: -8px;
  right: 5%;
  transform: rotate(45deg);
  border-top: 1px solid #f2f4f9;
  border-left: 1px solid #f2f4f9;
  z-index: -1;
}
.dropdown-menu p {
  color: #18214d;
  font-weight: 600;
}
.dropdown-menu a:hover {
  text-decoration: none;
}
.dropdown-menu a.text-muted:hover {
  color: #007bff !important;
}
.header-img-icon button img {
  width: 30px;
}
.header-custom-icon i {
  font-size: 20px;
  color: #758696;
}
.dropdown-header {
  padding: 12px 20px;
  font-size: 13px;
  border-bottom: 1px solid #f2f4f9;
}
.dropdown-body {
  padding: 5px;
  max-height: 290px;
  position: relative;
  overflow-y: auto;
}
.dropdown-body .dropdown-item {
  display: flex;
  align-items: center;
  padding: 9px 16px;
}
.dropdown-body .dropdown-item .content {
  margin-left: 15px;
  width: 200px;
  white-space: normal;
}
.dropdown-item .icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f7f7;
}
.dropdown-body .dropdown-item .content p {
  margin: 0;
  font-size: 13px;
  font-weight: 400;
}
.dropdown-body .dropdown-item .content p.sub-text {
  font-size: 12px;
}
.dropdown-footer {
  padding: 10px 20px;
  border-top: 1px solid #f2f4f9;
  font-size: 14px;
}

.dropdown-item:nth-child(1) .icon {
  background: rgba(114, 124, 245, 0.1);
}
.dropdown-item:nth-child(1) .icon i {
  color: #727cf5;
}
.dropdown-item:nth-child(2) .icon {
  background: rgba(255, 51, 102, 0.1);
}
.dropdown-item:nth-child(2) .icon i {
  color: #ff5680;
}
.dropdown-item:nth-child(3) .icon {
  background: rgba(102, 209, 209, 0.1);
}
.dropdown-item:nth-child(3) .icon i {
  color: #66d1d1;
}
.dropdown-item:nth-child(4) .icon {
  background: rgba(251, 188, 6, 0.1);
}
.dropdown-item:nth-child(4) .icon i {
  color: #fbbc06;
}
.dropdown-item:nth-child(5) .icon {
  background: rgba(16, 183, 89, 0.1);
}
.dropdown-item:nth-child(5) .icon i {
  color: #10b759;
}

.header-img-icon .dropdown-header {
  padding: 0px 20px;
}
.header-img-icon .profile-nav .nav-item .nav-link {
  font-size: 14px;
  padding: 5px 20px;
}
.header-img-icon .profile-nav {
  padding-bottom: 11px;
}
.header-img-icon .profile-nav .nav-item .nav-link:hover {
  color: #18214d;
  background-color: #f8f9fa;
}
.header-img-icon .profile-nav .nav-item .nav-link.red {
  color: #f74745;
}
.header-img-icon .profile-nav .nav-item .nav-link.red:hover {
  color: #f74745 !important;
}
.header-img-icon .dropdown-body {
  padding: 10px 0 0;
}
.header-img-icon .dropdown-header .name {
  font-size: 16px;
}
.header-img-icon .dropdown-header {
  padding: 20px 20px 0;
}
.header-img-icon .profile-nav .nav-item .nav-link i {
  margin-right: 15px;
}
