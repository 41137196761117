.wordings-editors {
    display: flex;
    gap: 2rem;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    width: 100%;

    & > div {
        display: flex;
        justify-content: center;
        flex-direction: column;
        justify-items: center;
        align-items: center;
    }
}